/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="css-b5m4nv --style2 --full pb--60 pt--60" name={"qv2cty7gbkf"} fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/d9a6578c84ac4f97ad981b24004c0637_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/d9a6578c84ac4f97ad981b24004c0637_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/d9a6578c84ac4f97ad981b24004c0637_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/d9a6578c84ac4f97ad981b24004c0637_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/d9a6578c84ac4f97ad981b24004c0637_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/d9a6578c84ac4f97ad981b24004c0637_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/d9a6578c84ac4f97ad981b24004c0637_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/18947/d9a6578c84ac4f97ad981b24004c0637_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--102 title-box--invert" content={"Kontakt"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--60" name={"j16sn98dpyl"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Václavské náměstí"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":650}} content={"Provozovna na Václavském náměstí je od 1. 4. 2024 uzavřena,\nhledáme pro vás nové prostory.\n\nDěkujeme za pochopení<br><br>Dům Módy, 1. patro<br>Václavské náměstí 804/58<br>110 00, Nové Město\n\n​<br><br>PO - PÁ | UZAVŘENO<br>SO - NE | UZAVŘENO<br><br>​Tel. :  +420 234 101 138\n<br>Email :  info@oliverscoffeecup.cz<br><br><span style=\"font-weight: bold;\">Provozovatel\n<br></span>BELIKOM s.r.o. <br>IČ: 02422557<br>DIČ: CZ 02422557\n\n​<br><br><span style=\"font-weight: bold;\">&nbsp;Odpovědná osoba\n<br></span>Jan Zeman"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Karlín"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":650}} content={"\nFuturama Business Park\n<br>Sokolovská 663/136F\n<br>186 00, Praha 8, Karlín\n\n​\n\n​<br><br>&nbsp;PO - PÁ | 8:00 - 18:00\n\n​<br><br><br>Tel. :  +420 725 323 325<br>Email :  info@oliverscoffeecup.cz<br><br><span style=\"font-weight: bold;\">Provozovatel</span><br>PM-JOIN s.r.o.<br>IČO: 09179119\n\n​<br>DIČ: CZ&nbsp;09179119​&nbsp;<br><br><span style=\"font-weight: bold;\">Odpovědná osoba<br></span>Petra Majeská"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--20 pb--60 pl--20 pr--20 pt--60">
          
          <ColumnWrap className="column__flex el--4 pl--0 pr--0 flex--top" columns={"4"}>
            
            <ColumnWrapper className="pl--20 pr--20">
              
              <Image className="--justify pl--30" alt={""} src={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png"} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":144}} srcSet={"https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=350x_.png 350w, https://cdn.swbpg.com/t/18947/92291b2f2f3f4415a5c7aba9c7625ab9_s=660x_.png 660w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">E-MAIL</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\">info@oliverscoffeecup.cz</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">TELEFON</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\">+420 234 101 138</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pl--20 pr--20">
              
              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0); font-weight: bold;\">SLEDUJTE NÁS</span>"}>
              </Text>

              <Text className="text-box text-box--center" content={"<span style=\"color: rgb(0, 0, 0);\"><a href=\"https://www.facebook.com/oliverscoffeecup.cz\" target=\"_blank\">Facebook<br></a><a href=\"https://www.instagram.com/oliverscoffeecup/\" target=\"_blank\">Instagram</a><br></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}